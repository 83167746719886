import styles from "./MediaPreviewModal.module.scss"
import ModalMotion, {
    AnimateDirection,
} from "@/components/Molecules/ModalMotion";
import { classNameGenerator } from "@/utils";

const MediaPreviewModal = ({
    pdfUrl,
    setMediaPreviewModal,
    mediaPreviewModal
}) => {
    const cls = classNameGenerator(styles);
    return (
        mediaPreviewModal ? (
            <ModalMotion
                overlayClosable
                onClose={() => setMediaPreviewModal(false)}
                width={"620px"}
                animateDirection={AnimateDirection.CENTER}
                borderRadius="4px"
                bodyBorderRadius="8px"
                showClose
                iconTopPosition="20px"
                iconRightPosition="20px"
                className={cls("previewImageModal")}
                bodyPadding="0px"
                closeIcon="icon-close-regular"
                iconFill="black"
                iconDimension="28px"
                iconPadding="7px"
                iconBorderRadius="50%"
                height="660px"
            >
                <div className={cls("imageWrapper")}>
                    <iframe
                        src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(pdfUrl)}`}
                        style={{ width: '100%', height: '100%' }}
                        frameBorder="0"
                        title="PDF Viewer"
                    />
                    <div style={{ width: "80px", height: "80px", position: "absolute", right: "-18px", top: "6px" }}>
                        <div></div>
                    </div>
                </div>
            </ModalMotion>
        ) : <></>
    )
}

export default MediaPreviewModal;